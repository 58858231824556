import React from "react";

const LandingPage = () => {
  return (
    <div>
      <div className="has-text-centered" id="booknow">
        {/* <img src={gif} /> */}
        {/* <button className="button" onClick={() => handleScrollToStats()}>Book Now</button> */}
      </div>
    </div>
  );
};
export default LandingPage;
